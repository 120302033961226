import React, { useState } from "react"
import usePageLoading from 'components/utils/usePageLoading'
import useContextTheme from 'components/utils/useContextTheme'

import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'

import Header from "components/header"
import CardLayout from 'components/elements/cards/cardLayout'

import FetchEvents from 'components/utils/fetchEvents'
import FetchPlaces from 'components/utils/fetchPlaces'

import "styles/pages.scss"
import "styles/map_page.scss"

const MapPage = props => {
    const { slug } = props

    const [embedded, setEmbedded] = useState(false)
    const pageLoader = usePageLoading()
    const theme = useContextTheme()

    const fetchEvents = true
    const fetchPlaces = true

    return (
        <div className={`page map ${pageLoader} ${embedded ? 'embedded' : ''} ${theme ? theme : ''}`}>
            <TemplateContextProvider
                embeddedCallback={setEmbedded}
                hasMap={true}
                tryLocation={true}
            >
                <PlacesContextProvider>
                    {/* Fetch data inside the place context; Instead of a hook, outside of context */}
                    {fetchEvents
                        ? <FetchEvents />
                        : null
                    }
                    {fetchPlaces
                        ? <FetchPlaces />
                        : null
                    }
                    <main>
                        {embedded != true
                            ? <Header />
                            : null
                        }

                        <CardLayout
                            slug={slug}
                            hasPlace
                            title='Explore the Map' />
                    </main>
                </PlacesContextProvider>

            </TemplateContextProvider>
        </div>
    )
}

export default MapPage