/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { pageStore } from "state/store-zustand";

import { Helmet } from "react-helmet"
import { helmetJsonLdProp  } from "react-schemaorg"

function SEO({
  lang = `en`,
  meta = [],
  data = [],
  fallbackImage = '',
  canonical,
  schemaData,
  title
}) {
  // Prop title or default title
  title = title
    ? title
    : data.title
      ? data.title
      : ""

  const embedded = pageStore((state) => state.embedded)
  const domain = 'https://vibemap.com'

  const seo = data

  const image = seo.opengraphImage
    ? seo.opengraphImage.mediaItemUrl
    : fallbackImage

  const baseURL = "https://vibemap.com"

  const json = [
    helmetJsonLdProp({
      "@context": "http://schema.org/",
      "@type": "Article",
      name: title,
      headline: title,
      abstract: seo.metaDesc,
      // TODO: How to handle orgs
      author: {
        '@type' : 'Person',
        "name": seo.opengraphAuthor
      },
      dateModified: seo.opengraphModifiedTime,
      datePublished: seo.opengraphPublishedTime,
      description: seo.metaDesc,
      image: image,
      inLanguage: lang,
      mainEntityOfPage: {
        '@type' : 'WebSite',
        "@id": baseURL
      },
      monetization: `$ilp.uphold.com/RZWDLnJdAwdP`,
      // TODO: add the geo field
      publisher: {
        "@type": "Organization",
        "name": "Vibemap"
      },
      url: `${baseURL}/${seo.opengraphUrl}`
    })
  ].concat(schemaData ? schemaData : [])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      script={json}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: seo.metaDesc,
        },
        {
          name: `apple-itunes-app`,
          content: `app-id=1496385897`,
        },
        {
          name: `google-play-app`,
          content: `app-id=com.vibemap.hotspots`,
        },
        {
          property: `og:title`,
          name: `name`,
          content: seo.opengraphTitle,
        },
        {
          property: `og:description`,
          name: `description`,
          content: seo.opengraphDescription,
        },
        {
          property: `og:type`,
          name: `type`,
          content: seo.opengraphType,
        },
        {
          property: `og:author`,
          name: `author`,
          content: seo.opengraphAuthor,
        },
        {
          property: `og:image`,
          name: `name`,
          content: image,
        },
        {
          property: `og:url`,
          name: `url`,
          content: seo.opengraphUrl
            ? seo.opengraphUrl.replace(/cms\./g, "")
            : "",
        },
        {
          name: `twitter:card`,
          content: "summary_large_image",
        },
        {
          name: `twitter:title`,
          content: seo.twitterTitle ? seo.twitterTitle : seo.opengraphTitle,
        },
        {
          name: `twitter:text:title`,
          content: seo.opengraphTitle,
        },
        {
          name: `twitter:description`,
          content: seo.twitterDescription ? seo.twiterDescription : seo.opengraphDescription,
        },
        {
          name : `pocket-site-verification`,
          content: `1645d6348fd2c213cd1cfdedbc5c84`
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name : `fo-verify`,
          content: `e5814c2a-549f-4c3b-93ad-3018ce10c909`
        },
        {
          name : `facebook-domain-verification`,
          content: `6kfsz28qak1ypjb1r6308j5ps3py1h`
        },
      ].concat(meta)}
    >
      <link rel="dns-prefetch" href="https://www.googletagmanager.com/"></link>
      {/* This is required because webpack ignores the CSS import? */}
      <link href='https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css' rel='stylesheet' />
      {canonical && <link rel="canonical" href={canonical} />}
      {embedded && <base target="_parent"/>}
    </Helmet>
  )
}

export default SEO